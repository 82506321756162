<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Publisher information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Vendor Name" v-if="publisher" v-model="publisher.name" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="publisher" v-model="publisher.street" autocomplete="off"></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-7">
            <input type="text" class="form-control" placeholder="City" maxlength="50" autocomplete="off" v-if="publisher" v-model="publisher.city">
          </div>

          <label class="col-md-1 col-form-label">PIN:</label>
          <div class="col-md-2">
            <input type="text" class="form-control  text-center" placeholder="Pincode" maxlength="7" v-if="publisher" v-model="publisher.pin" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">State:</label>
          <div class="col-md-3">
            <select data-placeholder="Select your state" class="form-control" v-if="publisher" v-model="publisher.state_cd" >
              <option value="37">Andhra Pradesh</option>
              <option value="35">Andaman and Nicobar Islands</option>
              <option value="12">Arunachal Pradesh</option>
              <option value="18">Assam</option>
              <option value="10">Bihar</option>
              <option value="04">Chandigarh</option>
              <option value="22">Chhattisgarh</option>
              <option value="26">Dadar and Nagar Haveli</option>
              <option value="25">Daman and Diu</option>
              <option value="07">Delhi</option>
              <option value="30">Goa</option>
              <option value="24">Gujarat</option>
              <option value="06">Haryana</option>
              <option value="02">Himachal Pradesh</option>
              <option value="01">Jammu and Kashmir</option>
              <option value="20">Jharkhand</option>
              <option value="29">Karnataka</option>
              <option value="32">Kerala</option>
              <option value="31">Lakshadweep</option>
              <option value="23">Madhya Pradesh</option>
              <option value="27">Maharashtra</option>
              <option value="14">Manipur</option>
              <option value="17">Meghalaya</option>
              <option value="15">Mizoram</option>
              <option value="13">Nagaland</option>
              <option value="21">Odisha</option>
              <option value="34">Pondicherry</option>
              <option value="03">Punjab</option>
              <option value="08">Rajasthan</option>
              <option value="11">Sikkim</option>
              <option value="33" selected="">Tamil Nadu</option>
              <option value="36">Telangana</option>
              <option value="16">Tripura</option>
              <option value="09">Uttar Pradesh</option>
              <option value="05">Uttarakhand</option>
              <option value="19">West Bangal</option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">GSTIN:</label>
          <div class="col-md-3">
            <input type="text" class="form-control text-center" placeholder="GSTIN" maxlength="15" v-if="publisher" v-model="publisher.gstin" autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">Tel:</label>
          <div class="col-md-2">
            <input type="text" class="form-control text-center" placeholder="Telephone" maxlength="10" v-if="publisher" v-model="publisher.tel" autocomplete="off">
          </div>

        </div>

        <div class="text-right">
          <SaveButton  @handle_save_action="perform_save_action"></SaveButton>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'PublicationForm',
  components: { SaveButton },
  data () {
    return {
      publisher: JSON.parse('{"id":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"gstin":"","email":"","web":"","tel":""}')
    }
  },
  store,
  component: {

  },
  props: {
    mypublisher: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"gstin":"","email":"","web":"","tel":""}')
    }
  },
  beforeMount () {
    console.log(JSON.stringify(this.mypublisher));
    this.publisher = this.mypublisher; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.publisher = JSON.parse('{"id":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"gstin":"","email":"","web":"","tel":""}')
  },
  mounted () {
    $('.form-control-select2').select2();
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('publisher_window_closed');
    },
    perform_save_action () {
      const self = this;

      try {

        if( self.$data.publisher.gstin == null){
          self.$data.publisher.gstin = "";
        }

        if (self.$data.publisher.name.trim().length < 3) {
          alert('Invalid Name');
          return
        } else if (parseInt(self.$data.publisher.state_cd) < 1) {
          alert('Invalid State selected');
          return
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.publisher.gstin = self.$data.publisher.gstin.toString().toUpperCase();
        self.$data.publisher.state_cd = parseInt(self.$data.publisher.state_cd);

        const requestOptions = {
          method: (self.$data.publisher.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.publisher)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publisher`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#txtname').focus()
              }, timer: 1500
            });
            self.publisher = JSON.parse('{"id":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"gstin":"","email":"","web":"","tel":""}');
            self.$emit('publisher_saved', resp.data);
          } else {
            swal({
              title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                $('#txtname').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
              $('#txtname').focus()
            }, timer: 3000
          });
        }).finally(() => {
          $('#mycard').unblock();
        });
      }catch (e) {
        $('#mycard').unblock();
        alert(e);
      }

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
